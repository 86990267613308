export const defaultPieData = [
  {
      "id": "Analyst",
      "label": "Analyst",
      "value": 5
  },
  {
      "id": "Information Technology, Engineering, Science",
      "label": "Information Technology, Engineering, Science",
      "value": 6
  },
  {
      "id": "Research, Analyst, Information Technology",
      "label": "Research, Analyst, Information Technology",
      "value": 6
  },
  {
      "id": "Information Technology, Analyst",
      "label": "Information Technology, Analyst",
      "value": 7
  },
  {
      "id": "Quality Assurance",
      "label": "Quality Assurance",
      "value": 8
  },
  {
      "id": "Education, Training",
      "label": "Education, Training",
      "value": 9
  },
  {
      "id": "Other",
      "label": "Other",
      "value": 23
  },
  {
      "id": "Business Development, Sales",
      "label": "Business Development, Sales",
      "value": 46
  },
  {
      "id": "Information Technology",
      "label": "Information Technology",
      "value": 128
  },
  {
      "id": "Engineering, Information Technology",
      "label": "Engineering, Information Technology",
      "value": 1517
  }
]