import React, {Component, lazy, Suspense} from "react";
import TitleComponent from "./TitleComponent"

import {defaultData} from "../TEMP/barMockedData"
import {defaultPieData} from "../TEMP/pieMockedData"
import {defaultSankeyData} from "../TEMP/sankeyMockedData"
import {defaultRadarData} from "../TEMP/radarMockedData"

const BarChartComponent = lazy(() => import('./BarChartComponent'));
const PieChartComponent = lazy(() => import('./PieChartComponent'));
const RadarChartComponent = lazy(() => import('./RadarChartComponent'));
const SankeyChartComponent = lazy(() => import('./SankeyChartComponent'));

class Content extends Component{

  render(){
    const { showSidebar } = this.props.globalProps;

    const contentStyle = {
      paddingTop: showSidebar ? 20 : this.props.globalProps.topBarHeight + 20,
      paddingRight: 20,
      paddingBottom: showSidebar ? 20 : this.props.globalProps.footerMenuHeight + 20,
      paddingLeft: showSidebar ? this.props.globalProps.sidebarWidth + 20 : 20,
      height: 2800 // HARDCODED, MUST CHANGE TO SOMETHING THAT WILL ADAPT BETTER TO SCREEN
    };

    const graphTitleStyle = {
      fontSize: 34
    }

    if (this.props.globalProps.professions_data.length > 0 && this.props.globalProps.selected_profession == ''){
      var professions_data = this.props.globalProps.professions_data;
      const randomElement = Math.floor(Math.random() * professions_data.length);
      this.props.globalProps.selectChartData(professions_data[randomElement]);
    }

    return(
      <div style={contentStyle}>

      <TitleComponent globalProps={this.props.globalProps}/>

      <br/>
      <Suspense fallback={<div>Loading...</div>}>
      <div style={graphTitleStyle} >Top 20 Technologies</div>
      <BarChartComponent
        data={this.props.globalProps.selected_profession_data.length > 0? this.props.globalProps.selected_profession_data[0]: defaultData} // Obviously must modify, however the initial state must be treated
        graphSizeStyle={{height: 600}}
      />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
      <div style={graphTitleStyle} >Industries</div>
      <PieChartComponent 
        data={this.props.globalProps.selected_profession_data.length > 0? this.props.globalProps.selected_profession_data[1]: defaultPieData} 
        graphSizeStyle={{height: 550}} 
        globalProps={this.props.globalProps}
      />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
      <div style={graphTitleStyle} >Seniority Level</div>
      <SankeyChartComponent
        data={this.props.globalProps.selected_profession_data.length > 0? this.props.globalProps.selected_profession_data[2]: defaultSankeyData}
        graphSizeStyle={{height: 600}}
      />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
      <div style={graphTitleStyle} >Skill chart</div>
      <RadarChartComponent 
        data={this.props.globalProps.selected_profession_data.length > 0? this.props.globalProps.selected_profession_data[3]: defaultRadarData} 
        graphSizeStyle={{height: 550}}
      />
      </Suspense>
    </div>
    )
  }
}

export default Content;