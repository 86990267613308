import React, {Component} from "react";

class TitleComponent extends Component {
    render(){
        var styleText = {
            fontSize: 48,
        }
        var styleSelectedProfession = {
            fontWeight: "bold"
        }
        return (
        <p style={styleText}>Statistics for <p style={styleSelectedProfession}>{this.props.globalProps.selected_profession.split("_").join(" ")}</p> </p>
        )
    }
}

export default TitleComponent;