export const defaultData = [
  {
      "Tech": "azure",
      "Hits": 16
  },
  {
      "Tech": "git",
      "Hits": 16
  },
  {
      "Tech": "deep learning",
      "Hits": 17
  },
  {
      "Tech": "numpy",
      "Hits": 20
  },
  {
      "Tech": "core",
      "Hits": 20
  },
  {
      "Tech": "scikit-learn",
      "Hits": 21
  },
  {
      "Tech": "excel",
      "Hits": 21
  },
  {
      "Tech": "tensorflow",
      "Hits": 22
  },
  {
      "Tech": "pandas",
      "Hits": 23
  },
  {
      "Tech": "java",
      "Hits": 24
  },
  {
      "Tech": "nosql",
      "Hits": 24
  },
  {
      "Tech": "hive",
      "Hits": 26
  },
  {
      "Tech": "bi",
      "Hits": 29
  },
  {
      "Tech": "tableau",
      "Hits": 30
  },
  {
      "Tech": "aws",
      "Hits": 34
  },
  {
      "Tech": "cloud",
      "Hits": 45
  },
  {
      "Tech": "r",
      "Hits": 82
  },
  {
      "Tech": "sql",
      "Hits": 101
  },
  {
      "Tech": "machine learning",
      "Hits": 111
  },
  {
      "Tech": "python",
      "Hits": 144
  }
]