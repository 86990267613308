import {
    GET_PROFESSIONS_START,
    GET_PROFESSIONS_SUCCESS,
    GET_PROFESSIONS_ERROR,
} from '../constants/actionTypes'

const defaultState = {
    professions_data: []

  };

export default (state = defaultState, action) => {
    switch (action.type) {
      case GET_PROFESSIONS_SUCCESS:
        return Object.assign({}, defaultState, {professions_data: action.professions_data});
      default:
        return state;
    }
  };
