import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducers from './reducers';

const getMiddleware = () => {
    if (process.env.NODE_ENV === 'production') {
      return applyMiddleware(thunk);
    } else {
      // Enable additional logging in non-production environments.
      return applyMiddleware(thunk, createLogger())
    }
  };

export const store = createStore(
    reducers, composeWithDevTools(getMiddleware()));
