import {
    ADJUST_SIZE
} from '../constants/actionTypes'

const defaultState = {
    windowWidth: 0 ,
    windowHeight: 0,
    showFooterMenuText: false,
    showSidebar: false,
    topBarHeight: 40,
    footerMenuHeight: 50,
    sidebarWidth: 50,
    sidebarCollapsed: true,
    white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
    black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`

  };

export default (state = defaultState, action) => {
    switch (action.type) {
      case ADJUST_SIZE:
        return Object.assign({}, defaultState, action.resizeParams, {
          showFooterMenuText: action.resizeParams.windowWidth > 500,
          showSidebar: action.resizeParams.windowWidth > 768,
          sidebarWidth: action.resizeParams.windowWidth < 1100 ? 50 : 150,
          sidebarCollapsed: action.resizeParams.windowWidth < 1100
        });
      default:
        return state;
    }
  };
