import React, {Component} from "react";
import ProfessionDropdown from "./ProfessionDropdown"
import InfoModal from "./InfoModal"

class TopBar extends Component {

  render(){
    const topBarStyle = {
      position: "fixed",
      zIndex: 999,
      top: 0,
      justifyContent: "space-between",
      textAlign: "center",
      lineHeight: "40px",
      display: "flex",
      width: "100%",
      height: this.props.globalProps.topBarHeight,
      backgroundColor: "#fff",
      borderBottom: "1px solid #d8d8d8",
      fontWeight: "bold",
      padding: "0px 20px",
      boxSizing: "border-box"
    };
    
    return(
      <div style={topBarStyle}>
        <div style={{display:"flex", width: "33%", justifyContent: "center"}}>
          <ProfessionDropdown globalProps={this.props.globalProps}/>
        </div>
        <div style={{display:"flex", width: "33%", justifyContent: "center"}}>
          StatisJob
        </div>
        <div style={{display:"flex", width: "33%", justifyContent: "center"}}>
          <InfoModal globalProps={this.props.globalProps} />
        </div>
    </div>
    )
  }
}

export default TopBar;