import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Component } from "react";
import axios from "axios";
import TopBar from "./components/Topbar";
import { Provider, connect } from 'react-redux';
import Content from "./components/Content";
import Sidebar from "./components/Sidebar";
import 
{ 
  ADJUST_SIZE, 
  GET_PROFESSIONS_START,
  GET_PROFESSIONS_SUCCESS,
  GET_PROFESSIONS_ERROR, 
  GET_PROFESSION_DATA_START, 
  GET_PROFESSION_DATA_SUCCESS, 
  GET_PROFESSION_DATA_ERROR 
} from "./constants/actionTypes"
import { store } from "./store"


// React Component
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Invoked immediately after a component is mounted
  componentDidMount() {
    // Call in first time to load the right component sizes
    this.updateDimensions();
    // Add a event listener to when the component resizes
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  

  updateDimensions() {
    // Get sizes
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    // Resize
    this.props.resizeComponents(windowWidth, windowHeight);

    // Get Professions List
    this.props.professionsGetData();
  }

  render() {
    console.log(this.props);
    return (
      <div
        style={{
          backgroundColor: this.props.black(0.05),
          minHeight: "100vh",
          position: "relative"
        }}
      >
        {this.props.showSidebar ? (
          <Sidebar globalProps={this.props}/>
        ) : (
          <TopBar globalProps={this.props} />
        )}
        <Content globalProps={this.props} />
      </div>
    );
  }
}

// Redux action
const resizeAction = (windowWidth, windowHeight) => {
  return {
    type: ADJUST_SIZE,
    resizeParams: {windowWidth, windowHeight}
  }
};

const professionsGetDataAction = () => {
  return dispatch => {
    dispatch({type: GET_PROFESSIONS_START});
    axios.get(process.env.REACT_APP_HOST_ADDRESS + '/api/professions/')
        .then(response => {
          dispatch({type:GET_PROFESSIONS_SUCCESS,professions_data: response.data})
        })
        .catch(error => {
            dispatch({type:GET_PROFESSIONS_ERROR})
        });
  }
};

const selectChartDataAction = (selected_profession) => {
  return dispatch => {
    dispatch({type: GET_PROFESSION_DATA_START, selected_profession});
    axios.get(process.env.REACT_APP_HOST_ADDRESS + '/api/search/' + selected_profession)
        .then(response => {
          dispatch({
            type:GET_PROFESSION_DATA_SUCCESS,
            selected_profession,
            selected_profession_data: response.data
          })
        })
        .catch(error => {
            dispatch({type:GET_PROFESSION_DATA_ERROR})
        });
  }
};


// Map the states to be accessed by props
const mapStateToProps = (state) => {
  return {
    windowWidth: state.resizeControl.responsewindowWidth,
    windowHeight: state.resizeControl.windowHeight,
    showFooterMenuText: state.resizeControl.showFooterMenuText,
    showSidebar: state.resizeControl.showSidebar,
    topBarHeight: state.resizeControl.topBarHeight,
    footerMenuHeight: state.resizeControl.footerMenuHeight,
    sidebarWidth: state.resizeControl.sidebarWidth,
    sidebarCollapsed: state.resizeControl.sidebarCollapsed,
    white: state.resizeControl.white,
    black: state.resizeControl.black,

    professions_data: state.professionsDataControl.professions_data,

    selected_profession: state.chartDataControl.selected_profession,
    selected_profession_data: state.chartDataControl.selected_profession_data

  }
};
// Map the dispatch functions to be accessed by props
const mapDispatchToProps = (dispatch) => {
  return {
    resizeComponents: (windowWidth, windowHeight) => {
      dispatch(resizeAction(windowWidth, windowHeight))
    },
    professionsGetData: () => {
      dispatch(professionsGetDataAction())
    },
    selectChartData: (selected_profession) => {
      dispatch(selectChartDataAction(selected_profession))
    }
  }
};

// Connect
const Container = connect(mapStateToProps, mapDispatchToProps)(App);

// Wrap container with the app and pass the store to provider
class AppWrapper extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Container/>
      </Provider>
    );
  }
};

export default AppWrapper;