import { combineReducers } from 'redux';
import resizeControl from './reducers/resizeControl';
import professionsDataControl from './reducers/professionsDataControl';
import chartDataControl from './reducers/chartDataControl'

// import { combineReducers } from 'redux'; // MUST IMPLEMENT ROUTES CORRECTLY

export default combineReducers({
    resizeControl,
    professionsDataControl,
    chartDataControl
});