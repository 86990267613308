import React, { Component, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import FormControl from 'react-bootstrap/FormControl'

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={Object.assign({}, style, {zIndex: '2'})}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
          )}
        </ul>
      </div>
    );
  },
);

class ProfessionSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuery: ""
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e){
    this.setState({selectedQuery: e});
    this.props.globalProps.selectChartData(e);
  }

  render() {
    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        style={{fontSize: this.props.globalProps.showSidebar ? 21: 16, 
                display: "block", 
                color: this.props.globalProps.showSidebar ? "white": "black"}}
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    ));
    console.log(this.props.globalProps)
    return (
      <Dropdown style={{textAlign: "center"}} onSelect={this.handleChange}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <span role="img" aria-label="profession dropdown">👷</span> {this.props.globalProps.sidebarCollapsed && this.props.globalProps.showSidebar? "" : "Professions"}
    </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          {this.props.globalProps.professions_data.map( profession_raw =>
            <Dropdown.Item eventKey={profession_raw} active={this.state.selectedQuery === profession_raw}>
              {profession_raw.split("_").join(" ")}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    )
  };
}

export default ProfessionSelection;