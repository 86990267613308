import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class InfoModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleClose() { this.setState({ show: false }) }
    handleShow() { this.setState({ show: true }) }


    render() {

        const buttonStyle = {
            background: "none",
            color: this.props.globalProps.showSidebar ? "white" : "black",
            border: "none",
            padding: 0,
            font: "inherit",
            cursor: "pointer",
            outline: "inherit",
            width: "100%",
            fontSize: this.props.globalProps.showSidebar ? 21 : 16,
        }
        
        return (
            <>
                <button style={buttonStyle} onClick={this.handleShow}>
                    <span role="img" aria-label="info icon">ℹ️</span> {(this.props.globalProps.sidebarCollapsed && this.props.globalProps.showSidebar) ? "" : "Info"}
                </button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>About this application</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p class="text-justify">
                        This applications has the purpose to capture data from job related websites like LinkedIn and return all the collected data in an easy and interactive graphic way showing the the most relevant skills for a certain carreer according to the actual demand of the market.
                    </p>
                    <hr/>
                    <div class="p-3">
                    <h2 class="text-center mt-n2 mb-4">Developer Team</h2>

                    <section  class="row border-top border-bottom p-2 shadow-sm">
                            <div class="col-md-6">
                                <img src="img/raphael-mazzine-perfil.jfif" alt="Developer Raphael Mazzine Picture" class= "w-100 rounded "/>
                            </div>
                            <div class="col-md-6 d-flex flex-column justify-content-center align-items-center ">
                                <figcaption class="mt-4 h3 text-center">Raphael<br/>Mazzine</figcaption>
                                <address class="mt-3">
                                    <a href="https://github.com/rmazzine"><img src="img/github.png" alt="Github Logo" width="45vw" /></a>
                                    <a href="https://www.linkedin.com/in/rmazzine/"><img src="img/linkedin.png" alt="LinkedIn Logo" width="45vw"  class="ml-4"/></a>
                                </address>
                            </div>
                    </section>
                    
                    <section  class="row border-top border-bottom p-2 shadow-sm">
                        <div class="col-md-6">
                            <img src="img/rafael-bizao-perfil.jfif" alt="Developer Rapfael Bizao" class= "w-100 rounded"/>
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
                            <figcaption class="h3 mt-4 text-center mt-3">Rafael<br/>Bizao</figcaption>
                            <address class="mt-3">
                                <a href="https://github.com/rabizao"><img src="img/github.png" alt="Github Logo" width="45vw" /></a>
                                <a href="https://www.linkedin.com/in/rafael-amatte-biz%C3%A3o-ab1285a5/"><img src="img/linkedin.png" alt="LinkedIn Logo" width="45vw"  class="ml-4"/></a>
                            </address>
                        </div>
                    </section>
                    <section  class="row border-top border-bottom p-2 shadow-sm">
                        <div class="col-md-6">
                            <img src="img/rafael-barros-perfil.jfif" alt="Developer Rafael Barros" class= "w-100 rounded"/>
                        </div>
                        <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
                            <figcaption class="h3 mt-3 text-center">Rafael<br/>Barros</figcaption>
                            <address class="mt-3">
                                <a href="https://github.com/fiusks"><img src="img/github.png" alt="Github Logo" width="45vw" /></a>
                                <a href="https://www.linkedin.com/in/rafael-barros-17081619/"><img src="img/linkedin.png" alt="LinkedIn Logo" width="45vw"  class="ml-4"/></a>
                            </address>
                        </div>
                    </section>
                    </div> 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
              </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}

export default InfoModal;