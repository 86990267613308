export const defaultRadarData = [
  {
    "Tech": "python",
    "cientista de dados": 144
  },
  {
    "Tech": "machine learning",
    "cientista de dados": 111
  },
  {
    "Tech": "sql",
    "cientista de dados": 101
  },
  {
    "Tech": "r",
    "cientista de dados": 82
  },
  {
    "Tech": "cloud",
    "cientista de dados": 45
  },
  {
    "Tech": "aws",
    "cientista de dados": 34
  },
  {
    "Tech": "tableau",
    "cientista de dados": 30
  },
  {
    "Tech": "bi",
    "cientista de dados": 29
  },
  {
    "Tech": "hive",
    "cientista de dados": 26
  },
  {
    "Tech": "nosql",
    "cientista de dados": 24
  },
  {
    "Tech": "java",
    "cientista de dados": 24
  },
  {
    "Tech": "pandas",
    "cientista de dados": 23
  },
  {
    "Tech": "tensorflow",
    "cientista de dados": 22
  },
  {
    "Tech": "excel",
    "cientista de dados": 21
  },
  {
    "Tech": "scikit-learn",
    "cientista de dados": 21
  },
  {
    "Tech": "core",
    "cientista de dados": 20
  },
  {
    "Tech": "numpy",
    "cientista de dados": 20
  },
  {
    "Tech": "deep learning",
    "cientista de dados": 17
  },
  {
    "Tech": "azure",
    "cientista de dados": 16
  },
  {
    "Tech": "git",
    "cientista de dados": 16
  }
]