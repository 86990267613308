import {
    GET_PROFESSION_START,
    GET_PROFESSION_DATA_SUCCESS,
    GET_PROFESSION_DATA_ERROR
} from '../constants/actionTypes'

const defaultState = {
    selected_profession: '',
    selected_profession_data: []

};

export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_PROFESSION_DATA_SUCCESS:
            return Object.assign({}, defaultState, {selected_profession: action.selected_profession,  selected_profession_data: action.selected_profession_data});
        default:
            return state;
    }
};
