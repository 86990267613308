import React, {Component} from "react";
import ProfessionDropdown from "./ProfessionDropdown"
import InfoModal from "./InfoModal"

class Sidebar extends Component {

  render(){

    const sidebarStyle = {
      height: "100vh",
      width: this.props.globalProps.sidebarWidth,
      position: "fixed",
      backgroundColor: this.props.globalProps.black(0.8),
      paddingTop: 40,
      zIndex: 1
    };
  
    const logoStyle = {
      textAlign: "center",
      color: this.props.globalProps.white(),
      fontSize: 30,
      marginBottom: 60,
      fontWeight: "bold"
    };
    
    return (
    <div style={sidebarStyle}>
      <div style={logoStyle}>{this.props.globalProps.sidebarCollapsed ? "SJ" : "StatisJob"}</div>
      <ProfessionDropdown globalProps={this.props.globalProps}/>
      <InfoModal globalProps={this.props.globalProps} />
    </div>
  );}
};

export default Sidebar;