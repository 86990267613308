export const defaultSankeyData = {
  "nodes": [
      {
          "id": "cientista de dados"
      },
      {
          "id": "Associate"
      },
      {
          "id": "Entry level"
      },
      {
          "id": "Internship"
      },
      {
          "id": "Mid-Senior level"
      },
      {
          "id": "Not Applicable"
      }
  ],
  "links": [
      {
          "source": "cientista de dados",
          "target": "Associate",
          "value": 62
      },
      {
          "source": "cientista de dados",
          "target": "Entry level",
          "value": 126
      },
      {
          "source": "cientista de dados",
          "target": "Internship",
          "value": 2
      },
      {
          "source": "cientista de dados",
          "target": "Mid-Senior level",
          "value": 38
      },
      {
          "source": "cientista de dados",
          "target": "Not Applicable",
          "value": 23
      }
  ]
}